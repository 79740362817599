@import ../../../../../common/css/colors.sass
@import ../../../../../common/css/fonts.sass

.microsoft
    border-color: $element-active
    width: inherit
    height: 46px
    color: $text-dark
    font-size: 16px
    font-family: $Khula-SemiBold
    line-height: 1

    &:hover, &:focus, &:active
        background-color: $background-color-light-gray-focus
        color: $text-dark
        border-color: $element-active
        box-shadow: none
        
    &__logo
        $size: 20px
        width: $size
        height: $size
        margin-right: 8px
