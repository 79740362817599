@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass

.updates-list
    padding-left: 0 !important
    padding-right: 2.1rem

.preview
    background-color: white
    transition: 0.3s
    padding: 0
    &__container
        margin-top: 5.7rem
        width: stretch
        width: -moz-available
        

.button-link
    display: inline-block
    width: min-content !important
    height: min-content !important

.btn-add
    min-width: 9rem
    height: 3rem
    background-color: $buttons-green-background-color
    color: $text-light
    font-family: $Khula-SemiBold
    font-size: 14px
    transition: 0.2s

    &:hover
        color: $text-light
        background-color: $buttons-green-background-color-focus
    &__icon
        width: 13px
        height: 13px
        mask-image: url("/Content/images/plus.svg")
        mask-repeat: no-repeat
        background-color: $text-light
        margin-right: 12px
    &__text
        padding-top: 4px
