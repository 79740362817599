@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass

.card
    padding: 20px 30px 33px 30px
    border-radius: 12px
    border-color: $border-gray-light

    &__info
        margin-bottom: 36px
        height: 44px

    &__image
        margin-bottom: 30px

    &__button
        height: 40px
        margin-bottom: 17px

    &__additional-info
        color: $text-gray
        font-size: 16px
        margin-bottom: 20px
        height: 80px
        display: flex

    &__link
        text-decoration: none
        color: $text-active
        font-family: $Khula-SemiBold
        font-size: 20px
        height: 50px
