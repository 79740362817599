@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass

.top-navigation
    align-items: center
    z-index: 100
    &__action-name
        padding-left: 0
        font-size: 24px
        line-height: 40px
        height: inherit
        font-family: $Khula-SemiBold

    &__logout
        display: flex
        border: none
        justify-content: space-between
        align-items: center
        transition: .3s
        &:hover
            .navigation__logout__icon
                background-color: $button-violer-dark

            .navigation__logout__text
                color: $text-dark

        &__icon
            mask-image: url('/Content/images/logout.svg')
            mask-repeat: no-repeat
            background-color: $button-violet
            width: 21px
            height: 24px
            margin-right: 7px
            transition: .3s

        &__text
            font-family: $Khula-Regular
            color: $text-gray
            font-size: 16px
            transition: .3s
            padding-top: 1px
