$boreders-light: #E7EDEF
$borders-green: #299291
$border-gray-light: #E6F0EE
$border-gray: #D2D8DD
$border-green-light: #3EA8A8

$element-active: #0E9292
$element-idle: #A6B1BB
$element-dark: #4D6276

//table
$table-border-color: #F2F4F8
$table-background-color: white
$table-border-active: #A3D3D2
$table-background-unactive: #f6f7f99d

// buttons
$background-color-light-gray: #FAFBFC
$background-color-light-gray-focus: #F2F3F4
$buttons-green-background-color: #0E9292
$buttons-green-background-color-focus: #058484
$button-violet: #7D61FA
$button-violer-dark: #5d47be

//text colors
$text-placeholder: #A6B1BB
$text-placeholder-light: #E9EBEE
$text-active: #0E9292
$text-active-dark: #0c8181
$text-idle: #364F65 
$text-dark: #203B54
$text-gray: #4D6276
$text-gray-light: #798998
$text-light: #FBFBFB