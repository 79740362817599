@import ../../../../../common/css/colors.sass
@import ../../../../../common/css/fonts.sass

.corner-info
    &__main
        color: $text-idle
        margin-bottom: 4px

    &__additional
        color: $text-gray-light
