@import ../../../../../common/css/colors.sass
@import ../../../../../common/css/fonts.sass

.menu-item

    &__border
        min-height: 3.5em
        margin-right: 30px
        &.active
            width: 3px
            background-color: $border-green-light
            border-top-right-radius: 15px
            border-bottom-right-radius: 15px

    &__link
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: flex-start
        align-items: center
        align-content: stretch
        width: inherit
        mask-repeat: no-repeat
        min-height: 3.5em
        transition: .1s

    &__icon
        $size: 24px
        width: $size
        height: $size
        background-color: $text-gray
        font-family: $Khula-Regular
        margin-right: 16px
        mask-repeat: no-repeat

        &.active
            background-color: $text-active

    &__text
        color: $text-gray
        font-family: $Khula-Regular
        &.active
            color: $text-active
            font-family: $Khula-SemiBold
