.container {
    padding-top: 4em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: center;
    font-size: medium;
    text-align: center;
}

.buttons {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}

.backButton {
    width: 8em;
    height: 3em;

    align-self: end;
    
    border: 1px solid rgba(186,9,9,0.21); 
    background: #f5f5f5;
    border-radius: 0.4em;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: medium;
    text-align: center;
    color: #3D3D3D;
    margin-right: 2em;
}

.confirmButton {
    width: 8em;
    height: 3em;

    align-self: end;
    
    border: 1px solid rgba(8,168,122,0.21);
    background: #f5f5f5;
    border-radius: 0.4em;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: medium;
    text-align: center;
    color: #3D3D3D;
    
}