@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass
@import ../../../../common/css/screen-sizes.sass
.action
    width: 20px
    height: 21px
    mask-repeat: no-repeat
    background-color: $element-dark
    &.edit
        
        mask-image: url("/Content/images/updates/edit.svg")
        @media only screen and (max-width: $screen-lg)
            padding-right: 1rem

        @media only screen and (min-width: $screen-lg)
            padding-right: 1.15rem

    &.delete
        
        mask-image: url("/Content/images/updates/delete.svg")

    &:hover
        &.edit, &.delete
            background-color: $element-active

    &__button
        padding-left: 0
        background-color: inherit
        border: none
        margin-right: 1.9rem
        &:hover, &:visited
            text-decoration: none
            background-color: inherit
            border: none
    &__text
        padding-left: 0.40rem
        padding-top: 2px
        color: $text-gray
        font-family: $Khula-SemiBold
        font-size: 14px

.action__button
    &:hover
        .action__text
            color: $element-active
        .action.edit, .action.delete
            background-color: $element-active