@import ../../../../common/css/colors.sass

.update-preview
    width: stretch
    width: -moz-available
    max-height: 100%
    flex-grow: 1
    border: 1px solid $border-gray
    box-shadow: 0px 4px 40px #6C7E9333
    height: 100px
    flex-wrap: wrap
    display: flex
    flex-direction: column
    box-sizing: border-box
    &__actions
        padding: 0px 0rem 2rem 1.7rem

        &__text
            text-transform: uppercase
            color: $text-placeholder
        &__item
            padding-top: 2.5rem

        &__container
            padding-left: 2rem

    &__item
        width: inherit
        max-height: 100%
        &.padding
            padding-left: 16px
            padding-right: 16px

        &__placeholder
            background-color: $text-placeholder-light
            &.first
                margin-bottom: 15px

                background-color: $text-placeholder-light
                border-radius: 8px
                margin-bottom: 7px
                height: 15px

    &__iframe
        width: inherit
        max-height: 98%

.cursor-default
    cursor: default

.inactive
    pointer-events: none
