@import ../../../common/css/colors.sass
@import ../../../common/css/fonts.sass

.btn-add
    min-width: 9rem
    height: 3rem
    background-color: $buttons-green-background-color
    color: $text-light
    font-family: $Khula-SemiBold
    font-size: 14px
    transition: 0.2s

    &:hover
        color: $text-light
        background-color: $buttons-green-background-color-focus
    &__icon
        width: 13px
        height: 13px
        mask-image: url("/Content/images/plus.svg")
        mask-repeat: no-repeat
        background-color: $text-light
        margin-right: 12px
    &__text
        padding-top: 4px


.form-label
    font-family: $Khula-Regular
    font-size: 14px



.confirm-action
    color: white
    min-width: 5rem
    font-size: 14px
    background-color: $buttons-green-background-color !important
    &:hover, &:focus
        background-color: $buttons-green-background-color-focus
        color: white

.modal-title
    padding-top: 6px
