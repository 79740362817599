.modify-update
    &__top-navigation
        margin-bottom: 37.5px
        margin-top: 6.6rem

.preview
    background-color: white
    transition: 0.3s
    padding: 0
    align-items: stretch
    height: auto
    &__container
        margin-top: 5.7rem
        width: stretch
        width: -moz-available
