@import ../../../../../common/css/colors.sass
@import ../../../../../common/css/fonts.sass

.update-body
    font-family: $Khula-Regular
    font-size: 14px
    color: $text-idle
    label
        margin-bottom: 0
    input
        box-sizing: border-box
        border-radius: 4px
        border: solid
        border-color: $boreders-light
        border-width: 1px
        &.datetime
            height: 50px
            padding: 0 7px 0 10px
            margin-right: 14px
            font-family: $Khula-SemiBold
            color: $text-dark

    ::placeholder
        color: $text-placeholder
    button
        font-family: $Khula-Regular
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator
        display: none
        -webkit-appearance: none

.modify-update
    &__headline
        padding-bottom: 44px
        
    &__header
        padding-bottom: 34px
        width: 550px
    
    &__release-options
        display: flex
        flex-direction: column
        width: 19rem
        margin-bottom: 30.5px
        &.open
            justify-content: space-between
            margin-bottom: 10.5px

    &__dropdown
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

    &__release-date
        display: flex
        flex-direction: row
        justify-content: space-between
        width: fit-content
        padding-top: 15px

.headline
    width: inherit
    height: 50px
    padding-left: 16px

.version
    width: 262px
    height: 40px
    padding-left: 16px

.toggle
    position: relative
    display: inline-block
    width: 40px
    height: 20px
    input
        opacity: 0
        width: 0
        height: 0
        &:checked + .slider
            background-color: $element-active
        &:focus + .slider
            box-shadow: 0 0 1px $element-idle
        &:checked + .slider::before
            transform: translateX(18px)

.slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $element-idle
    transition: .4s

    &::before
        position: absolute
        content: ""
        width: 12px
        height: 12px
        top: 4px
        left: 4px
        background-color: white
        transition: .4s

    &.round
        border-radius: 34px
        &::before
            border-radius: 50%

.show > .custom-bootstrap-dropdown.btn-primary
    background-color: $background-color-light-gray
    color: $text-active
    border-color: $boreders-light
    box-shadow: none
    padding-left: 16px
    &:focus, &:focus-visible
        box-shadow: none

.custom-bootstrap-dropdown, .custom-bootstrap-dropdown.btn-primary
    color: $text-idle
    background-color: white
    border-color: $boreders-light
    box-shadow: none
    width: inherit
    text-align: left
    padding-left: 16px
    height: 40px
    line-height: 30px
    &::after
        float: right
        margin-top: 12px
    &:focus, &:focus-visible
        color: $text-idle
        background-color: white
        border-color: $boreders-light
        box-shadow: none
    &:hover, &:active, &:active:focus, &:checked
        background-color: $background-color-light-gray
        color: $text-active
        border-color: $boreders-light
        box-shadow: none
    &__menu
        width: inherit
    &.show
        background-color: $background-color-light-gray
        color: $text-active
        border-color: $boreders-light
        box-shadow: none

.dropdown
    $dropdown-height: 40px
    position: relative
    display: inline-block
    width: 271px
    height: $dropdown-height
    &__content
        display: none
        width: inherit
        height: inherit
        position: absolute
        background-color: #f9f9f9
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
        z-index: 1
        &__element
            color: black
            padding: 12px 16px
            width: inherit
            height: inherit
            cursor: pointer
            text-decoration: none
            display: block
            background-color: white
            &:hover
                background-color: #f1f1f1
        &.open
            display: block
    &__button
        background-color: white
        color: $text-idle
        text-align: left
        width: inherit
        height: inherit
        font-size: 14px
        cursor: pointer
        border: 1px solid $boreders-light
        &__text
            line-height: $dropdown-height
        &__icon
            width: 24px
            height: 24px
            background-image: url('/Content/images/arrow-down.svg')
            background-repeat: no-repeat

.separator
    height: 1px
    border: 1px solid #E9EBEE
    margin-bottom: 25.5px

.editor
    width: inherit

.image-uploader
    &__input
        display: none

    &__label
        border-radius: 4px
        border: 1px dotted $border-gray
        padding: 4px 8px
        cursor: pointer
        
    &__notice
        margin-left: 8px
