@import ../../../../../../common/css/colors.sass

  
.image-uploader
  &__input
    display: none

  &__label
    border-radius: 4px
    border: 1px dotted $border-gray
    padding: 4px 8px
    cursor: pointer
      
  &__notice
    margin-left: 8px
