$font-path: "/Content/fonts"

$Khula-Regular: "Khula-Regular"   
@font-face
    src: url($font-path + "/Khula-Regular.ttf")

    font-family: $Khula-Regular


$Khula-SemiBold: "Khula-SemiBold"   
@font-face
    src: url($font-path + "/Khula-SemiBold.ttf")

    font-family: $Khula-SemiBold

$Khula-Bold: "Khula-Bold"   
@font-face
    src: url($font-path + "/Khula-Bold.ttf")

    font-family: $Khula-Bold