@import ../../../common/css/colors.sass

.layout
    height: inherit
    &__logo
        padding: 8px 15px 42px 30px
        &__image
            max-width: 180px

.navigation
    padding-left: 2.5rem
    height: inherit
    &__left
        padding-left: 0
        height: min-content
        
    &__top
        position: absolute
        width: inherit
    &.gray
        background-color: $background-color-light-gray
.main-content
    height: inherit
    padding-top: 5.8rem
.navbar-toggler
    margin-bottom: 18px
