@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass

.user-actions-spacing
    margin-right: 10px

.remove-product
    width: 20px
    height: 21px
    mask-repeat: no-repeat
    background-color: $element-dark
    mask-image: url("/Content/images/updates/delete.svg")
    cursor: pointer
    &:hover
        background-color: $element-active

.edit-product
    width: 20px
    height: 21px
    mask-repeat: no-repeat
    background-color: $element-dark
    mask-image: url("/Content/images/updates/edit.svg")
    cursor: pointer
    &:hover
        background-color: $element-active

.leave-product
    width: 20px
    height: 21px
    mask-repeat: no-repeat
    background-color: $element-dark
    mask-image: url("/Content/images/updates/exit.svg")
    cursor: pointer
    &:hover
        background-color: $element-active
        
.confirm-action
    color: white
    min-width: 5rem
    font-size: 14px
    background-color: $buttons-green-background-color !important
    &:hover, &:focus
        background-color: $buttons-green-background-color-focus
        color: white

.modal-title
    padding-top: 6px