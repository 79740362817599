@import ../../../common/css/colors.sass
@import ../../../common/css/fonts.sass
@import ../../../common/css/screen-sizes.sass

.products-row
    overflow-y: scroll
    max-height: 400px
    
.products-modal
    max-width: 400px

.selected-product
    font-family: $Khula-Bold
    font-size: 17px
    margin-left: 5px


.change-product
    font-family: $Khula-SemiBold
    font-size: 17px
    padding-left: 7px


.product-selector
     font-family: $Khula-Regular
     font-size: 17px


.btn-product
    background-color: $buttons-green-background-color
    color: $text-light
    font-family: $Khula-SemiBold
    font-size: 17px
    transition: 0.2s

    &:hover
        color: $text-light
        background-color: $buttons-green-background-color-focus

