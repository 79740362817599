@import ../../../../../common/css/colors.sass
@import ../../../../../common/css/fonts.sass

.back-to-list
    $height: 22px
    display: flex
    margin-top: 0.7rem
    flex-direction: row
    justify-content: space-between
    background-color: rgba(0, 0, 0, 0)
    border: 0px
    width: fit-content
    height: $height
    font-family: $Khula-SemiBold
    color: $buttons-green-background-color
    transition: .3s
    &:hover, &:focus
        color: $buttons-green-background-color-focus
    &__arrow
        width: 19px
        height: 19px
        background-color: $buttons-green-background-color
        mask-image: url('/Content/images/backward-navigation-arrow-left.svg')
        mask-repeat: no-repeat
        margin-right: 3px 
    &__text
        line-height: 22.5px
