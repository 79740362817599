@import ../../../common/css/colors.sass
@import ../../../common/css/fonts.sass

.btn-add
    min-width: 9rem
    height: 3rem
    background-color: $buttons-green-background-color
    color: $text-light
    font-family: $Khula-SemiBold
    font-size: 14px
    transition: 0.2s

    &:hover
        color: $text-light
        background-color: $buttons-green-background-color-focus
    &__icon
        width: 13px
        height: 13px
        mask-image: url("/Content/images/plus.svg")
        mask-repeat: no-repeat
        background-color: $text-light
        margin-right: 12px
    &__text
        padding-top: 4px

.show > .custom-bootstrap-dropdown.btn-primary
    background-color: $background-color-light-gray
    color: $text-active
    border-color: $boreders-light
    box-shadow: none
    padding-left: 16px
    &:focus, &:focus-visible
        box-shadow: none

.custom-bootstrap-dropdown, .custom-bootstrap-dropdown.btn-primary
    color: $text-idle
    background-color: white
    border-color: $boreders-light
    box-shadow: none
    width: inherit
    text-align: left
    padding-left: 16px
    height: 40px
    line-height: 30px
    &::after
        float: right
        margin-top: 12px
    &:focus, &:focus-visible
        color: $text-idle
        background-color: white
        border-color: $boreders-light
        box-shadow: none
    &:hover, &:active, &:active:focus, &:checked
        background-color: $background-color-light-gray
        color: $text-active
        border-color: $boreders-light
        box-shadow: none
    &__menu
        width: inherit
    &.show
        background-color: $background-color-light-gray
        color: $text-active
        border-color: $boreders-light
        box-shadow: none

.dropdown
    $dropdown-height: 40px
    position: relative
    display: inline-block
    width: 271px
    height: $dropdown-height
    &__content
        display: none
        width: inherit
        height: inherit
        position: absolute
        background-color: #f9f9f9
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
        z-index: 1
        &__element
            color: black
            padding: 12px 16px
            width: inherit
            height: inherit
            cursor: pointer
            text-decoration: none
            display: block
            background-color: white
            &:hover
                background-color: #f1f1f1
        &.open
            display: block
    &__button
        background-color: white
        color: $text-idle
        text-align: left
        width: inherit
        height: inherit
        font-size: 14px
        cursor: pointer
        border: 1px solid $boreders-light
        &__text
            line-height: $dropdown-height
        &__icon
            width: 24px
            height: 24px
            background-image: url('/Content/images/arrow-down.svg')
            background-repeat: no-repeat