@import ../../../common/css/colors.sass
@import ../../../common/css/fonts.sass

.loader
    height: inherit
    display: flex
    flex-direction: column
    flex-wrap: wrap
    justify-content: center
    align-items: center
    align-content: center

.login
    height: inherit

.login-container

    &__logo
        width: 205.5px
        height: 65px
        margin-bottom: 62px
        
    &__welcome
        margin-bottom: 14px
        color: $text-idle

    &__login-text
        $height: 45px
        height: $height
        line-height: $height
        font-size: 36px
        font-family: $Khula-Regular

    &__authorization
        margin-top: 30px
        margin-bottom: 30px

    &__footer
        font-family: $Khula-Regular
        color: $text-dark

    &__login-text, &__help
        color: $text-dark

    &__welcome, &__help
        font-family: $Khula-SemiBold

.info-container
    background-color: #E7F4F4

    &__text
        text-align: center
        max-width: 490px
        font-size: 48px
        line-height: 1
        font-family: $Khula-Bold
        padding-bottom: 5rem
        z-index: 3
    &__image
        max-width: 490px
        margin-bottom: 2rem
        z-index: 3

.triangle
    position: fixed
    width: 0
    height: 0
    border-left: 200px solid transparent
    border-right: 200px solid transparent
    border-bottom: 300px solid #FFF8EC
    z-index: 2
    opacity: 0.5
    &.top
        transform: rotate(87deg)
        top: -38px
        right: 10px

    &.bottom-right
        right: -144px
        bottom: -44px
        transform: rotate(-47deg)
        border-left: 320px solid transparent
        border-right: 320px solid transparent
        border-bottom: 420px solid #FFF8EC

    &.bottom-left
        ight: 165px
        bottom: -240px
        transform: rotate(-25deg)
        border-left: 320px solid transparent
        border-right: 257px solid transparent
        border-bottom: 420px solid #FFF8EC
