@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass

.remove-user
    width: 20px
    height: 21px
    mask-repeat: no-repeat
    background-color: $element-dark
    mask-image: url("/Content/images/updates/delete.svg")
    cursor: pointer
    &:hover
        background-color: $element-active

.delete-user
    color: white
    border-radius: 4px
    background-color: $buttons-green-background-color
    border: 0.5px solid $borders-green
    height: 40px
    font-family: $Khula-SemiBold
    font-size: 14px
    transition: .3s
    line-height: 2.1
    &:hover, &:focus
        background-color: $buttons-green-background-color-focus
        color: white

.modal-title
    padding-top: 6px
