@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass
@import ../../../../common/css/screen-sizes.sass

$min-table-width: 770px

.user-table
    border-collapse: inherit
    border-color: $table-border-color
    border-width: 1px
    min-width: $min-table-width

    &.element-selected
        tr
            transition: 0.2s
            &:not(.update.active)
                background-color: $table-background-unactive

    &:hover
        tr
            transition: 0.2s
            &:not(:hover)
                background-color: $table-background-unactive

.user
    background-color: $table-background-color
    font-family: $Khula-Regular
    min-height: 54px
    color: $text-dark
    font-size: 12px
    transition: 0.2s
    cursor: pointer
   
    &__head-item
        padding-top: 16px !important
        padding-left: 10px !important

    @media only screen and (max-width: $screen-lg)
        &.update__head-item
            padding-left: 6px !important
        .user__actions
            opacity: 1

    @media only screen and (min-width: $screen-lg)
        &.active
            outline: $table-border-active solid 1px
            background-color: $table-background-color !important

        &:hover, &:active, &:target
            &.head
                background-color: $table-background-color !important
                box-shadow: none
                transform: none

            --bs-table-accent-bg: $table-background-color
            z-index: 200
            background-color: $table-background-color !important

            .user__actions
                opacity: 1

    &__item
        font-size: 14px
        padding: 14px 0px 10px 14px !important
        &__label-container
            padding-left: 0px !important
        &__actions-container
            padding: 0px !important
            padding-bottom: 3px !important
        &.bold
            font-family: $Khula-SemiBold
        &__label
            text-align: center
            border-radius: 10px
            padding: 9px
            font-family: $Khula-SemiBold
            height: 28px
            min-width: 90px
            padding-top: 10px

    &__actions
        transition: 0.1s
        opacity: 0

    &.head
        background-color: $table-background-color
        text-transform: uppercase
        color: $text-placeholder
        font-family: $Khula-SemiBold
        