@import ../../../../common/css/colors.sass
@import ../../../../common/css/fonts.sass
@import ../../../../common/css/screen-sizes.sass




.nav-item 
        color: $text-dark !important
        &:hover
                color: $text-dark


.nav-link
            color: $text-dark !important
            padding-left: 0px !important
            &:hover
                    color: $text-dark !important


.dropdown-toggle
             color: $text-dark !important
             &:hover
                    color: $text-dark

.dropdown
        color: $text-dark !important
        &:hover
                color: $text-dark !important

