.widgetBody {
    padding: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;
    box-sizing: border-box;
    height: 60em;
}

.widgetBody pre {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 30em;
}

.widget {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;
    width: 56em;
}

.reactSupportCheckbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
}

.editArea {
    display: flex;
}

.exampleArea {
    display: flex;
    flex-direction: column;
    width: 28em;
    font-size: small;
}

.exampleArea textarea {
    height: 10em;
}