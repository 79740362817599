@import ../../../../../common/css/colors.sass
@import ../../../../../common/css/fonts.sass

.change-update-state
    width: fit-content

.save-button
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    color: $text-active
    padding-top: 2px
    flex-wrap: wrap
    border-radius: 4px
    background-color: $background-color-light-gray
    border: 0.5px solid $borders-green
    width: 98px
    height: 40px
    font-family: $Khula-SemiBold
    font-size: 14px
    transition: .3s
    &:hover, &:focus
        background-color: $background-color-light-gray-focus
        
    &__image
        width: 20px
        height: 21px
        mask-image: url('/Content/images/save-icon.svg')
        mask-repeat: no-reapeat
        background-color: $buttons-green-background-color
        margin-right:  10.5px

.publish-button
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    color: white
    padding-top: 2px
    flex-wrap: wrap
    border-radius: 4px
    background-color: $buttons-green-background-color
    border: 0.5px solid $borders-green
    width: 98px
    height: 40px
    font-family: $Khula-SemiBold
    font-size: 14px
    transition: .3s
    &:hover, &:focus
        background-color: $buttons-green-background-color-focus
    &__image
        width: 17px
        height: 17px
        mask-image: url('/Content/images/publish-icon.svg')
        mask-repeat: no-reapeat
        background-color: white
        margin-right:  10.5px
        margin-bottom: 2px 
    
