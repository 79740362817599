@import ../../../../../../common/css/colors.sass
@import ../../../../../../common/css/fonts.sass

.custom-tooltip
    max-width: 40rem
    visibility: hidden
    opacity: 0
    font-family: $Khula-SemiBold
    font-size: 14px
    position: fixed
    z-index: 100000
    margin-top: 7rem
    padding: 2rem 2rem
    background-color: $table-border-color
    border-radius: 14px
    &__main-content
        &:hover
            ~ .custom-tooltip
                visibility: visible
                opacity: 1
                transition: visibility 0s, opacity 0.2s
                
