@import ../../../../../common/css/colors.sass
@import ../../../../../common/css/fonts.sass
.card-button
    height: inherit
    &__button
        width: 125px
        height: inherit
        background-color: $buttons-green-background-color
        border-radius: 8px

        &:hover, &:focus
            background-color: $buttons-green-background-color-focus

        &__icon
            width: 20px
            height: 20px
            background-color: $border-gray-light
            margin-right: 12px

        &__text
            color: $text-light
            font-family: $Khula-SemiBold
            font-size: 14px
            padding-top: 2px
