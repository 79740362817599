@import ../../../common/css/colors.sass
@import ../../../common/css/fonts.sass

.username
    margin-top: 4.6rem
    width: max-content
    margin-bottom: 30px
    font-family: $Khula-SemiBold
    font-size: 36px
    color: $text-dark
    $username-height: 40px
    height: $username-height
    line-height: $username-height

.navigation-cards
    padding: 0
    margin-right: 24px
    min-width: 140px
